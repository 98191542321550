<template>
  <div>
    <el-card class="card-box">
      <SubTitle title="认证方式"></SubTitle>
      <div class="auth_box">
        <div class="auth_one">
          <p>方式一：</p>
          <a :href="authUrl">官网认证</a>
        </div>
        <div class="auth_two">
          <p>方式二：</p>
          <div class="img">
            <div style="width: 200px; height: 200px" class="qrcode" ref="qrCodeUrl"></div>
          </div>
        </div>
      </div>
      <!-- <div class="box">
        <div>
          <span class="font-color-danger">*</span>
          <span class="label">企业名称：</span>
          <span style="font-weight:bold;">{{ enterpriseName }}</span>
        </div>
        <div>
          <span class="font-color-danger">*</span>
          <span class="label">打款状态：</span>
          <span class="font-color-brand">
            {{
            state | enterpriseCertiState
            }}
          </span>
          <el-button
            size="mini"
            type="primary"
            style="margin-left:22px;"
            @click="getEnterpriseInfo"
          >刷新状态</el-button>
        </div>
        <div style="margin-left:96px;" v-if="state === '2'">
          <el-input
            v-model="amount"
            placeholder="请输入对公打款金额进行校验"
            style="width:217px;display:inline-block;line-height: 30px;"
          ></el-input>
          <el-button size="mini" type="primary" style="margin-left:22px;" @click="amountVertify">校验</el-button>
        </div>
        <div style="text-align:center">
          <el-button type="primary" @click="toNext">完成</el-button>
        </div>
      </div>-->
    </el-card>
  </div>
</template>

<script>
import { api } from '/src/api/base';

import QRCode from 'qrcodejs2'

//查询企业信息
export const enterpriseDescribe = api()('enterprise.describe.json');
// 企业打款认证
const enterpriseCheckPayAmount = api()('enterprise.check.pay.amount.json');
export default {
  components: {
    SubTitle: () => import('/src/components/subTitle.vue')
  },
  props: {
    stepActive: Number
  },
  data () {
    return {
      enterpriseName: localStorage.getItem('enterpriseName') || '',
      state: '',
      amount: null,
      code: '',
      authUrl: ''
    };
  },
  async created () {
    this.code = this.$route.query.code || window.localStorage.getItem('enterprise');
    this.authUrl = window.localStorage.getItem('authUrl')



    this.getEnterpriseInfo();
  },
  mounted () {
    this.$nextTick(() => {
      this.creatQrCode()
    })
  },
  methods: {
    creatQrCode () {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.authUrl, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      console.log(qrcode);
    },
    async getEnterpriseInfo () {
      const res = await enterpriseDescribe({
        code: this.code
      });
      this.enterpriseName = res.name;
      this.state = res.authenticateState;
    },
    async amountVertify () {
      if (
        !this.amount ||
        this.amount === 0 ||
        this.amount === 1 ||
        this.amount < 0 ||
        this.amount > 1
      ) {
        return this.$message.error('金额不正确！');
      }
      await enterpriseCheckPayAmount({
        code: this.code,
        amount: this.amount
      });
      this.$message.success('企业认证成功');
      setTimeout(() => {
        this.getEnterpriseInfo();
        this.toNext();
      }, 100);
    },
    toNext () {
      this.$emit('update:stepActive', 4);
    }
  }
};
</script>

<style lang="scss" scoped>
.auth_box {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  .auth_two {
    width: 50%;
    display: flex;
  }
  .auth_one {
    width: 50%;
    display: flex;
  }
}
.box {
  margin-left: 31px;
  margin-right: 25px;
  font-size: 14px;
  color: #535353;
  div {
    margin-bottom: 20px;
  }
}
.label {
  display: inline-block;
  margin-right: 16px;
}
</style>
